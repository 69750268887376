import React from "react";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
} from "@mui/material";

const projects = [
  {
    title: "Math Solver",
    description:
      "Advanced AI-powered calculator that solves complex math problems step-by-step",
    image: require("../images/cards/math.png"),
    link: "https://apps.apple.com/us/app/ai-math-calculator-mathbot/id6682705979",
  },
  {
    title: "Orbit",
    description:
      "Intelligent mobile chat assistant that enhances productivity and provides instant information",
    image: require("../images/cards/orbit.png"),
    link: "https://apps.apple.com/us/app/orbit-gpt-o1-chat-assistant/id6612007544?platform=iphone",
  },
  {
    title: "DAN",
    description:
      "Innovative AI chatbot designed to push the boundaries of conversational AI technologyChat assistant for your phone",
    image: require("../images/cards/pht.png"),
    link: "https://apps.apple.com/us/app/dan-ai-voice-chat-companion/id6502860590",
  },
];

const Projects = () => {
  return (
    <div>
      <Typography variant="h2" gutterBottom sx={{ mb: { xs: 2, md: 4 } }}>
        My Projects
      </Typography>
      <Grid container spacing={{ xs: 2, md: 3 }}>
        {projects.map((project, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card sx={{ height: "100%" }}>
              <CardActionArea
                href={project.link}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardMedia
                  component="img"
                  height="140"
                  image={project.image}
                  alt={project.title}
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="h5" component="div">
                    {project.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {project.description}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Projects;
