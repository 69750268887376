import React from "react";
import { Box, IconButton, Link } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import TwitterIcon from "@mui/icons-material/Twitter";

const SocialLinks = () => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
      <Link
        href="https://github.com/berkaygemici"
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconButton size="large" sx={{ mx: 1 }}>
          <GitHubIcon fontSize="inherit" />
        </IconButton>
      </Link>
      <Link
        href="https://twitter.com/berkayygemicii"
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconButton size="large" sx={{ mx: 1 }}>
          <TwitterIcon fontSize="inherit" />
        </IconButton>
      </Link>
      <Link
        href="https://www.linkedin.com/in/berkay-gemici/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconButton size="large" sx={{ mx: 1 }}>
          <LinkedInIcon fontSize="inherit" />
        </IconButton>
      </Link>
    </Box>
  );
};

export default SocialLinks;
