import React from "react";
import { Typography, Container, Box } from "@mui/material";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{ mt: "auto", py: 3, backgroundColor: "background.paper" }}
    >
      <Container maxWidth="lg">
        <Typography variant="body2" color="text.secondary" align="center">
          © {new Date().getFullYear()} · Berkay Gemici
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          align="center"
          sx={{ mt: 1 }}
        >
          contact@berkaygemici.com.tr
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
