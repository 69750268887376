import React from "react";
import { Typography, Box, Avatar } from "@mui/material";
import ReactTypingEffect from "react-typing-effect";
import { motion } from "framer-motion";

const Biography = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1.5 }}
    >
      <Box sx={{ textAlign: "center" }}>
        <Avatar
          src={require("../images/cards/IMG_5656.jpg")}
          alt="Berkay Gemici"
          sx={{
            width: { xs: 150, sm: 200 },
            height: { xs: 150, sm: 200 },
            margin: "0 auto",
            mb: 3,
          }}
        />
        <Typography variant="h1" gutterBottom>
          Berkay Gemici
        </Typography>
        <Typography variant="h3" gutterBottom>
          <ReactTypingEffect
            text={["Full-Stack Developer", "AI Enthusiast", "IOS Developer"]}
            speed={100}
            eraseSpeed={100}
            typingDelay={1000}
            eraseDelay={2000}
          />
        </Typography>
        <Typography variant="body1" paragraph sx={{ px: { xs: 2, sm: 0 } }}>
          I'm a passionate full-stack developer with a love for building
          AI-integrated projects and playing with real-world data. Currently,
          I'm a business informatics student at TU Braunschweig, deeply enamored
          with the world of technology.
        </Typography>
        <Typography variant="body1" paragraph sx={{ px: { xs: 2, sm: 0 } }}>
          With over 3 years of freelance experience, I've honed my skills in
          cloud computing and various development scenarios. I'm always striving
          to identify problems, design solutions, and apply them with a broad
          perspective when developing software architectures.
        </Typography>
      </Box>
    </motion.div>
  );
};

export default Biography;
