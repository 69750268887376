import { useState } from "react";
import { Container, Grid, Box } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Brightness3Icon from "@mui/icons-material/Brightness3";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import { Fade } from "@mui/material";
import ScrollToTop from "./static/components/ScrollToTop";

import Footer from "./static/components/Footer";
import SocialLinks from "./static/components/SocialLinks";
import Projects from "./static/components/Projects";
import Biography from "./static/components/Biography";
import "./App.css";

export default function App() {
  const [toggleDarkMode, setToggleDarkMode] = useState(true);

  const toggleDarkTheme = () => {
    setToggleDarkMode(!toggleDarkMode);
  };

  const theme = createTheme({
    palette: {
      mode: toggleDarkMode ? "dark" : "light",
      primary: {
        main: "#4caf50",
      },
      secondary: {
        main: "#8bc34a",
      },
      background: {
        default: toggleDarkMode ? "#121212" : "#f5f5f5",
        paper: toggleDarkMode ? "#1e1e1e" : "#ffffff",
      },
    },
    typography: {
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      h1: {
        fontSize: "3rem",
        fontWeight: 700,
        marginBottom: "1rem",
      },
      h2: {
        fontSize: "2.5rem",
        fontWeight: 600,
        marginBottom: "0.75rem",
      },
      h3: {
        fontSize: "2rem",
        fontWeight: 600,
        marginBottom: "0.5rem",
      },
      body1: {
        fontSize: "1rem",
        lineHeight: 1.6,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 8,
            textTransform: "none",
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: 12,
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          },
        },
      },
    },
  });

  document.body.className = toggleDarkMode ? "dark" : "light";

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Fade in={true} timeout={1000}>
        <Box
          sx={{
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Container maxWidth="lg" sx={{ flexGrow: 1, py: { xs: 2, md: 4 } }}>
            <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
              <IconButton
                onClick={toggleDarkTheme}
                size="large"
                sx={{
                  transition: "transform 0.3s ease-in-out",
                  "&:hover": {
                    backgroundColor: "transparent",
                    transform: "rotate(180deg)",
                  },
                  animation: toggleDarkMode ? "iconChange 0.5s" : "none",
                }}
              >
                {toggleDarkMode ? <WbSunnyIcon /> : <Brightness3Icon />}
              </IconButton>
            </Box>
            <Grid container spacing={{ xs: 2, md: 4 }}>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  transition: "transform 0.3s ease-in-out",
                  "&:hover": { transform: { xs: "none", md: "scale(1.02)" } },
                }}
              >
                <Biography />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  transition: "transform 0.3s ease-in-out",
                  "&:hover": { transform: { xs: "none", md: "scale(1.02)" } },
                }}
              >
                <Projects />
              </Grid>
            </Grid>
          </Container>
          <Box sx={{ mt: "auto", mb: 2 }}>
            <SocialLinks />
          </Box>
          <Footer />
        </Box>
      </Fade>
      <ScrollToTop />
    </ThemeProvider>
  );
}
